export default [
  {
    key: 'labelKm',
    label: 'field.labelKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'labelEn',
    label: 'field.labelEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'sequenceOrder',
    label: 'field.sequenceOrder',
    rules: 'required|min_value:1',
    type: 'currency',
  },
  {
    cols: 12,
    hide: true,
    skip: true,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
